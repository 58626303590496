import React, { useEffect, useState } from 'react';
import style from "./Organization.module.css";
import { baseUrl } from "../Url";
import { BsPersonCircle } from 'react-icons/bs';
import { AiOutlineClose } from 'react-icons/ai';
import { Alert, Button, Checkbox, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Snackbar } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};



function OrgUserMap() {
    const [partnerData, setPartnerData] = useState([])
    const [selectedPartner, setSelectedPartner] = useState(null);
    const [mapOrg, setMapOrg] = useState(false);
    const [orgData, setOrgData] = useState([]);
    const [selectedOrgIds, setSelectedOrgIds] = useState([]);
    const [mapSnack, setMapSnack] = useState(false);
    const [mapDescription, setMapDescription] = useState("");
    const [partnerOrgMapData, setPartnerOrgMapData] = useState({});
    const [selectedPartnerId, setSelectedPartnerId] = useState("");



    const userRole = localStorage.getItem("User");
    const userId = localStorage.getItem("userId");
    const orgId = localStorage.getItem("OrgId");

    const handleCheckboxChange = (org_id) => {
        const newSelectedClubs = selectedOrgIds.includes(org_id)
          ? selectedOrgIds.filter((id) => id !== org_id)
          : [...selectedOrgIds, org_id];
    
          setSelectedOrgIds(newSelectedClubs);
      };

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        // setSelectedOrgIds(
        //     typeof value === 'string' ? value.split(',') : value,
        // );
        setSelectedOrgIds(value);
    };

    const handlePartnerChange = async (e) => {
        const selectedPartnerUsername = e.target.value;
        setSelectedPartnerId(selectedPartnerUsername)
        const partner = partnerData.find(p => p.user_id === selectedPartnerUsername);
        setSelectedPartner(partner);
        // setSelectedOrgIds(partnerOrgMapData[partner?.Username] || []);
    };

    async function organizationGet() {
        const reqData = {
            role: userRole,
            user_id: userId
        }
        await fetch(
            `${baseUrl}/organizations/list`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(reqData)
            }
        )
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                setOrgData(data.data);
            });
    }

 async function partnerOrgMap() {
        const reqData = {
            user_id: selectedPartner.user_id,
            org_id: selectedOrgIds
        }
        await fetch(
            `${baseUrl}/partners/org/map`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(reqData)
            }
        )
            .then((res) => res.json())
            .then((data) => {
                console.log(data, 'mapped org');
                if (data.status == true) {
                    setPartnerOrgMapData((prev) => ({
                        ...prev,
                        [selectedPartner.Username]: selectedOrgIds
                    }));
                    setMapOrg(false);
                    setMapDescription(data.description);
                    setMapSnack(true);
                    getPartnerList();
                }
                // else {
                //     setMapDescription(data.description);
                //     setMapSnack(true);
                // }
            });
    }

    async function getPartnerList() {
        await fetch(
            `${baseUrl}/partners/list`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
            .then((res) => res.json())
            .then((data) => {
                console.log(data, 'list role data');
                if (data.status === true) {
                    setPartnerData(data.data);
                    if(selectedPartnerId !== ""){
                        const partner = data.data.find(p => p.user_id === selectedPartnerId);
                        setSelectedPartner(partner);
                    }
                }
            });
    }

    useEffect(() => {
        getPartnerList();
    }, []);
    return (
        <div className={style.Container}>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={mapSnack}
                onClose={() => {
                    setMapSnack(false);
                }}
                autoHideDuration={3000}
            >
                <Alert
                    variant="filled"
                    severity="success"
                    sx={{ width: "100%", fontSize: "1.15rem" }}
                >
                    {mapDescription}
                </Alert>
            </Snackbar>
            <div className={style.Header}>
                <div className={style.HeaderText}>
                    <p>Add User To Organizations</p>
                </div>
            </div>
            <div className={style.orgSection}>
                <div className={style.selection}>
                    <label for="partner-select" className={style.partnerLabel}>
                        Select Partner
                    </label>
                    <select
                        className={style.partnerSelect}
                        name=""
                        id="partner-select"
                        onChange={handlePartnerChange}
                        value={selectedPartnerId}
                    >
                        <option value="">Select Partner</option>
                        {partnerData.length > 0 &&
                            partnerData.map((partner, i) => {
                                return (
                                    <option
                                        value={partner.user_id}
                                        key={partner.user_id}
                                    >
                                        {partner.Username}
                                    </option>
                                );
                            })}
                    </select>
                </div>
            </div>
            {selectedPartner && (
                <div className={style.userManagement}>
                    <div className={style.List}>
                        <div className={style.userCards} >
                            <div className={style.userCardTop}>
                                <div className={style.userImage}>
                                    <BsPersonCircle />
                                </div>
                                <div className={style.userDetail}>
                                    <h6>
                                        <span> User Name :</span> {selectedPartner.Username}
                                    </h6>
                                    <p>
                                        <span> Role :</span> {selectedPartner.Role}
                                    </p>
                                </div>
                            </div>
                            <div className={style.cardBotton}>
                                <button
                                    onClick={() => {
                                        setMapOrg(true);
                                        organizationGet()
                                        const selctedIds = selectedPartner.org_details.map((data)=>data.org_id)
                                        setSelectedOrgIds(selctedIds)
                                    }}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* <div className={style.pageContainer}>
                        <Pagination
                            pageCount={Math.ceil(totalUsers / itemsPerPage)}
                            onPageChange={handlePageChange}
                        />
                    </div> */}
                </div>
            )}

            {mapOrg ? (
                <>
                    <div
                        onClick={() => {
                            setMapOrg(false);
                        }}
                        className={style.Overlay}
                    ></div>
                    <div className={style.modalDropSec}>
                        <div className={style.popupClose}>
                            <AiOutlineClose
                                onClick={() => {
                                    setMapOrg(false);
                                }}
                            />
                        </div>
                        <div className={style.popupSection}>
                            <FormControl sx={{ m: 1, width: 400 }}>
                                <InputLabel id="demo-multiple-name-label">Select Organization</InputLabel>
                                <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    multiple
                                    value={selectedOrgIds}
                                    onChange={handleChange}
                                    input={<OutlinedInput label="Select Organization" />}
                                    MenuProps={MenuProps}
                                >

                                    {
                                        orgData.length > 0 &&
                                        orgData.map((org, index) => {
                                            const isSelected = selectedOrgIds.includes(org.org_id);
                                            return (
                                                <MenuItem
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}
                                                    key={org.org_id}
                                                    value={org.org_id}
                                                >
                                                    {" "}
                                                    {org.org_name}
                                                    <Checkbox
                                                        name={org.org_id}
                                                        id={org.org_id}
                                                        checked={isSelected}
                                                        onChange={() => handleCheckboxChange(org.org_id)}
                                                    />
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ width: '150px' }}
                                    onClick={() => {
                                        partnerOrgMap()
                                    }}
                                >
                                    Map User
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                ""
            )}
        </div>


    )
}

export default OrgUserMap