import React, { useEffect, useState } from "react";
import style from "./Organization.module.css";
import { useNavigate } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineClose } from "react-icons/ai";
import { HiOutlineViewGrid } from "react-icons/hi";
import { baseUrl } from "../Url";
import { LuUpload } from "react-icons/lu";


const Organization = () => {
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);
  const [orgName, setOrgName] = useState("");
  const [editName, setEditName] = useState("");
  const [description, setDescription] = useState("");
  const key = JSON.parse(localStorage.getItem("access-token"));
  const [orgData, setOrgData] = useState([]);
  const [orgEdit, setOrgEdit] = useState(false);
  const [orgnizationDelete, setOrganizationDelete] = useState(false);
  const [orgDescription, setOrgDescription] = useState("");
  const [orgId, setOrgId] = useState("");
  const [deleteOrgId, setDeleteOrgId] = useState("");
  const [image, setImage] = useState({ preview: '', data: '' })
  const [imageUpload, setImageUpload] = useState(false)
  const [deleteOrgName, setDeleteOrgName] = useState("")
  const [selectedType, setSelectedType] = useState("")
  const [companyData, setCompanyData] = useState([]);
  const [partnerOrgMapData, setPartnerOrgMapData] = useState({});
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [selectedPartnerId, setSelectedPartnerId] = useState("");
  const [partnerData, setPartnerData] = useState([])
  const [selectedOrgIds, setSelectedOrgIds] = useState([]);

  const userRole = localStorage.getItem("User");
  const userId = localStorage.getItem("userId");

  async function organizationGet() {
    const reqData = {
      role: userRole,
      user_id: userId
    }
    await fetch(
      `${baseUrl}/organizations/list`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqData)
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setOrgData(data.data);
      });
  }

  async function OrganisationAdd() {
    await fetch(`${baseUrl}/organization/add`, {
      method: "POST",
      body: JSON.stringify({ org_name: orgName, description: description }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(async(data) => {
        if (data.status === true) {
          const newOrgId = data.data[0].organizations_id;
          setPopup(false);
          organizationGet();
          if (userRole === "Partner"){
          await partnerOrgMap(newOrgId);
          }
        }
        console.log(data,'adddddd');
        // organizationGet();
      });
  }

  async function orgUpdate(e) {
    await fetch(`${baseUrl}/organisation/edit`, {
      method: "POST",
      body: JSON.stringify({
        org_id: orgId,
        org_name: editName,
        description: orgDescription,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setOrgEdit(false);
          organizationGet();
          setEditName("");
          setOrgDescription("");
        }
      });
  }

  async function orgDelete() {
    await fetch(`${baseUrl}/organisation/delete`, {
      method: "POST",
      body: JSON.stringify({
        org_id: deleteOrgId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          organizationGet();
          setOrganizationDelete(false);
        }
      });
  }

  async function logoUpload() {
    let formData = new FormData()
    formData.append('image', image.data);
    formData.append("org_id", orgId);
    await fetch(`${baseUrl}/organisation/logo/upload`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setImageUpload(false)
          organizationGet()
        }
      });
  }

  async function getPartnerList() {
    await fetch(
        `${baseUrl}/partners/list`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        }
    )
        .then((res) => res.json())
        .then((data) => {
            console.log(data, 'list role data');
            if (data.status === true) {
                setPartnerData(data.data);
                if(userId !== ""){
                    const partner = data.data.find(p => p.user_id === userId);
                    const selctedIds = partner.org_details?.map((data)=>data.org_id)
                    setSelectedOrgIds(selctedIds)
                }
            }
        });
}

  async function partnerOrgMap(newOrgId) {
    const existingOrgIds = selectedOrgIds ? selectedOrgIds : [];
    const reqData = {
        user_id: userId,
        org_id: [...existingOrgIds, newOrgId]
    }
    console.log(reqData);
    await fetch(
        `${baseUrl}/partners/org/map`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(reqData)
        }
    )
        .then((res) => res.json())
        .then((data) => {
            console.log(data, 'mapped org');
            if (data.status == true) {
              organizationGet()
            }
        });
}

  const addRow = () => {
    setCompanyData([...companyData, { company_name: "", company_des: "" }]);
  };

  const removeRow = (index) => {
    const updatedcompanyData = [...companyData];
    updatedcompanyData.splice(index, 1);
    setCompanyData(updatedcompanyData);
  };

  const handleInputChange = (index, fieldName, value) => {
    const updatedcompanyData = [...companyData];
    updatedcompanyData[index][fieldName] = value;
    setCompanyData(updatedcompanyData);
  };



  const handleFileChange = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    }
    setImage(img)
  }

  useEffect(() => {
    organizationGet();
  }, [key]);

  useEffect(() => {
if (userRole === "Partner"){
  getPartnerList();
}
}, []);

  return (
    <div className={style.Container}>
      {imageUpload ?
        <>
          <div
            className={style.ProfileOverlay}
            onClick={() => {
              setImageUpload(false)
            }}
          ></div>
          <div className={style.ProfilePopup}>
            <form action="" >
              <div className={style.Formtext}>
                <p>Please choose Your Image</p>
              </div>
              <input
                type="file"
                name=""
                id=""

                accept="image/png, image/jpeg"
                onChange={(e) => {
                  handleFileChange(e)
                }}
              />
              <div className={style.FormButton}>
                <button disabled={!image.data} type="button" onClick={() => {
                  logoUpload()
                }}>upload</button>
              </div>
            </form>
          </div>
        </>
        : ''
      }
      <div className={style.Header}>
        <div className={style.HeaderText}>
          <p>Organizations</p>
        </div>
        <div className={style.mapButton}>
          {userRole === "SuperAdmin"   ?(
            <button
              onClick={() => {
                navigate("/organization/user/map");
              }}
            >
              Map Organization
            </button>

          ) : (
            ""
          )}
        </div>
        <div className={style.HeadButton}>
          {userRole === "SuperAdmin" || userRole === "Partner"  ? (
            <button
              onClick={() => {
                setPopup(true);
              }}
            >
              Create
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className={style.orgSection}>
        <div className={style.Grid}>
          {orgData && orgData.length > 0
            ? orgData.map((item, index) => {
              return (
                <div key={index} className={style.Cards}>
                  <div className={style.CardLeft}>
                    <div className={style.LogoImage}>
                      <img src={item.profile} />
                    </div>
                  </div>
                  <div className={style.CardRight}>
                    <div className={style.cardTop}>
                      <p>{item.org_name}</p>
                    </div>
                    <div className={style.cardBottom}>
                      <div className={style.iconContainer}>
                        <LuUpload
                          className={style.editButton}
                          onClick={() => {
                            setImageUpload(true)
                            setOrgId(item.org_id);
                          }}
                        />
                        <span className={style.tooltipText}>Upload</span>
                      </div>
                      <div className={style.iconContainer}>
                        <BiEdit
                          className={style.editButton}
                          onClick={() => {
                            setOrgEdit(true);
                            setEditName(item.org_name);
                            setOrgDescription(item.description);
                            setOrgId(item.org_id);
                          }}
                        />
                        <span className={style.tooltipText}>Edit</span>
                      </div>
                      {userRole === "SuperAdmin" || userRole === "Partner" ? (
                        <div className={style.iconContainer}>
                          <RiDeleteBinLine
                            className={style.deleteButton}
                            onClick={() => {
                              setOrganizationDelete(true);
                              setDeleteOrgId(item.org_id);
                              setDeleteOrgName(item.org_name)
                            }}
                          />
                          <span className={style.tooltipText}>Delete</span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              );
            })
            : ""}
        </div>
      </div>

      {popup ? (
        <>
          <div
            onClick={() => {
              setPopup(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.AddressSection}>
            <div className={style.popupClose}>
              <AiOutlineClose
                onClick={() => {
                  setPopup(false);
                }}
              />
            </div>
            <div action="" className={style.popupSection}>
              {/* <select
            name=""
            id=""
            onChange={(e) => {
              const type = e.target.value;
              setSelectedType(type)
             
              
            }}
          >
            <option value="">Select Type</option>
            <option value="Company">Company</option>
            <option value="Partner">Partner</option>
            
          </select> */}
              {/* {selectedType==="Company"?
              <> */}
              <label className={style.topLabel}>Organization</label>
              <input
                type="text"
                name=""
                id=""
                required
                onChange={(e) => {
                  setOrgName(e.target.value);
                }}
                placeholder=""
              />
              <label className={style.topLabel}>Description</label>
              <input
                type="text"
                name=""
                id=""
                required
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                placeholder=""
              />
              {/* </>
              :
              selectedType==="Partner"?
              <>
              <label className={style.topLabel}>Main Company Name</label>
                <input
                  type="text"
                  name=""
                  id=""
                  required
                  onChange={(e) => {
                    
                  }}
                  placeholder=""
                />
                <label className={style.topLabel}>Description</label>
                <input
                  type="text"
                  name=""
                  id=""
                  required
                  onChange={(e) => {
                    
                  }}
                  placeholder=""
                />
                 <div className={style.InputContainer}>
                <div className={style.InputLabel}>Partner Companies:</div>
                <div className={style.Input}>
                  <button onClick={addRow}>Add</button>
                </div>
              </div>
                <div className={style.PartnerCompany}>
                <table>
                  <tbody>
                    {companyData.map((param, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            placeholder="Company Name"
                            value={param.parameter_key}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "company_name",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            placeholder="company_des Des"
                            value={param.parameter_value}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "parameter_value",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <button onClick={() => removeRow(index)}>
                            Remove
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                </div>
              </>
              :''
              } */}
            </div>
            <div className={style.popupButton}>
              <button
                className={style.create}
                onClick={() => {
                  OrganisationAdd();
                }}
              >
                Create
              </button>
              <button
                className={style.cancel}
                onClick={() => {
                  setPopup(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {orgEdit ? (
        <>
          <div
            onClick={() => {
              setOrgEdit(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.AddressSection}>
            <div className={style.popupClose}>
              <AiOutlineClose
                onClick={() => {
                  setOrgEdit(false);
                }}
              />
            </div>
            <div action="" className={style.popupSection}>
              <input
                type="text"
                value={editName}
                required
                onChange={(e) => {
                  setEditName(e.target.value);
                }}
                placeholder="Organization"
              />
              <input
                type="text"
                value={orgDescription}
                required
                onChange={(e) => {
                  setOrgDescription(e.target.value);
                }}
                placeholder="Description"
              />
            </div>
            <div className={style.popupButton}>
              <button
                className={style.create}
                onClick={() => {
                  orgUpdate();
                }}
              >
                Update
              </button>
              <button
                className={style.cancel}
                onClick={() => {
                  setOrgEdit(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {orgnizationDelete ? (
        <>
          <div
            onClick={() => {
              setOrganizationDelete(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.deletePopup}>
            <div className={style.deleteClose}>
              <AiOutlineClose
                onClick={() => {
                  setOrganizationDelete(false);
                }}
              />
            </div>
            <div className={style.deleteSection}>
              <div className={style.popupText}>
                <p>Are you sure you want to delete <br /><span>{deleteOrgName}</span> ?</p>
              </div>
              <div className={style.popupDelete}>
                <button
                  className={style.orgDelete}
                  onClick={() => {
                    orgDelete();
                  }}
                >
                  Delete
                </button>
                <button
                  className={style.orgDeleteCancel}
                  onClick={() => {
                    setOrganizationDelete(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Organization;
