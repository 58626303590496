import React,{useEffect} from 'react'
import style from './Home.module.css'
import { useNavigate } from "react-router-dom";
const Home = () => {
const navigate=useNavigate()
  const key =JSON.parse(localStorage.getItem("access-token"));
  const user=localStorage.getItem("User")

  useEffect(()=>{
      if(user===null||user===undefined){
        navigate("/login")
      }
  },[user])
  return (
    <div className={style.container}>
    <div>Home</div>

    </div>
  )
}

export default Home