import React, { useState, useEffect } from "react";
import style from "./Login.module.css";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../Url";
import { FcGoogle } from "react-icons/fc";
import { BiLogoInstagram } from "react-icons/bi";
import { FaFacebook } from "react-icons/fa6";

import { HiOutlineChevronRight, HiOutlineChevronLeft } from "react-icons/hi";
import { Alert, Snackbar } from "@mui/material";

const Login = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [id, setId] = useState(0);
  const [loginError, setLoginError] = useState(false);
  const [errorDescription, setErrorDescription] = useState("");

  const AboutData = [
    "https://t3.ftcdn.net/jpg/03/54/00/74/360_F_354007466_mm4QilA3n92YWPseqs82gbWxbb06R1i4.jpg",
    "https://miro.medium.com/v2/resize:fit:702/1*Ra02AqsQlC0KV229EvM98g.png",
    "https://media.istockphoto.com/id/1407983911/photo/forex-diagrams-and-stock-market-rising-lines-with-numbers.jpg?s=612x612&w=0&k=20&c=zas1h6LR6v2iCvE7SWnVoZ_s7ZSiboN45UK0d5oMWac=",
  ];

  const rightHandle = () => {
    if (id >= AboutData.length - 1) {
      setId(0);
    } else {
      setId(id + 1);
    }
  };

  async function login() {
    await fetch(`${baseUrl}/account/login/adminpanel`, {
      method: "POST",
      body: JSON.stringify({
        Username: username,
        Password: password,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "logindata");
        if (data.status === true) {
          const userRole = data.data[0].Role;
          if (userRole === "SuperAdmin" || userRole === "Partner") {
            navigate("/organization");
          } else if (userRole === "OrganizationAdmin") {
            navigate("/user");
          } else {
          }
          localStorage.setItem("User", data.data[0].Role);
          localStorage.setItem("OrgId", data.data[0].OrgID);
          localStorage.setItem("Orgname", data.data[0].org_name);
          localStorage.setItem("Orgimage", data.data[0].profile);
          localStorage.setItem("userId", data.data[0].UserID);
        } else {
          setErrorDescription(data.description);
          setLoginError(true);
        }
      });
  }

  useEffect(() => {
    const time = setTimeout(() => {
      rightHandle();
    }, 5000);

    return () => clearInterval(time);
  }, [id]);

  return (
    <div className={style.Container}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={loginError}
        onClose={() => {
          setLoginError(false);
        }}
        autoHideDuration={3000}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {errorDescription}
        </Alert>
      </Snackbar>
      <div className={style.loginContainer}>
        <div className={style.loginPage}>
          <div className={style.pageLeft}>
            <div className={style.leftPageHeader}>
              <div className={style.headerLogo}>
                <img
                  src="https://tasshamjit.ai/wp-content/uploads/2024/01/AT-1-1024x576.png"
                  alt="not available"
                />
              </div>
              <div className={style.headerText}>
                <p>Business intelligence at your finger tips </p>
              </div>
            </div>
            <div className={style.leftPageCentre}>
              <div className={style.signIn}>
                <h3>Sign In</h3>
                <p>to access your dashboard</p>
              </div>
              <div className={style.input}>
                <input
                  type="email"
                  placeholder="Email"
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                />
                <input
                  type="password"
                  placeholder="Password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                <button
                  onClick={() => {
                    login();
                  }}
                >
                  Sign In
                </button>
              </div>
            </div>
            <div className={style.leftPageBottom}>
              <div className={style.bottomHeader}>
                <p>Our Services</p>
              </div>
              <div className={style.iconSet}>
                <FcGoogle />
                <BiLogoInstagram />
                <FaFacebook />
              </div>
            </div>
          </div>
          <div className={style.pageRight}>
            <div className={style.sliderContainer}>
              <div className={style.Slider}>
                <img src={AboutData[id]} alt="" />
              </div>
              <div className={style.SliderNav}>
                {AboutData.map((item, index) => {
                  return (
                    <div className={style.CircleNav}>
                      <button
                        onClick={() => {
                          setId(index);
                        }}
                        className={
                          id === index ? style.Active : style.NonActive
                        }
                      ></button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
