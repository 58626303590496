import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './store'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_TEST_KEY}`);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
     {/* <Elements stripe={stripePromise}> */}
      <App />
    {/* </Elements> */}
  </Provider>
);

