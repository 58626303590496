import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Components/Home/Home";
import Layout from "./Components/Layout";
import User from "./Components/UserManagement/User";
import Profile from "./Components/Profile/Profile";
import Register from "./Components/Register/Register";
import Organization from "./Components/Organization/Organization";
import Login from "./Components/Login/Login";
import OrgUserMap from "./Components/Organization/OrgUserMap";
import ResetPassword from "./Components/ResetPassword/ResetPassword";


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
        <Route path='/login' element={<Login />} />

          <Route path='/register' element={<Register />} />

          <Route element={<Layout />}>
            <Route path="/" element={<Organization />} />
            <Route path='/user' element={<User />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/organization' element={<Organization />} />
            <Route path="/organization/user/map" element={<OrgUserMap/>}/>
            <Route path="/reset/password" element={<ResetPassword/>}/>
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
