import React, { useState, useEffect } from "react";
import style from "./User.module.css";
import { BsPersonCircle } from "react-icons/bs";
import { RiShareForwardFill } from "react-icons/ri";
import { RiDeleteBinLine } from "react-icons/ri";
import { RiSettingsLine } from "react-icons/ri";
import { BiEdit } from "react-icons/bi";
import { FcSearch } from "react-icons/fc";
import { AiOutlineClose } from "react-icons/ai";
import { BiLockOpenAlt } from "react-icons/bi";
import { baseUrl } from "../Url";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RoleGet } from "../../action/User";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";
import Pagination from "../Pagination/Pagination";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { PiPasswordBold } from "react-icons/pi";
import { BsGraphUpArrow } from "react-icons/bs";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { IoMdShareAlt } from "react-icons/io";
import { Snackbar } from '@mui/material';

const User = () => {
  const key = JSON.parse(localStorage.getItem("access-token"));
  const adminId = localStorage.getItem("adminUserId");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userRole = localStorage.getItem("User");
  const userIdForOrg = localStorage.getItem("userId");
  const orgID = localStorage.getItem("OrgId")

  const { roles } = useSelector((state) => state.roleData);

  const [userData, setUserData] = useState([]);
  const [orgList, setOrgList] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState("");
  const [selectedCLubId, setSelectedClubId] = useState("");
  const [clubList, setClubList] = useState([]);
  const [clubReadOnly, setClubReadOnly] = useState(true);
  const [userShow, setUserShow] = useState(false);
  const [roleChange, setRoleChange] = useState(false);
  const [roleData, setRoleData] = useState([]);
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [selectedRoleName, setSelectedRoleName] = useState("");
  const [mentorChange, setMentorChange] = useState(false);
  const [mentorList, setMentorList] = useState([]);
  const [selectedMentorId, setSelectedMentorId] = useState("");
  const [mappedMentorList, setMappedMentorList] = useState(false);
  const [singleMentorList, setSingleMentorList] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [memberCreate, setMemberCreate] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [url, setUrl] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [editUserName, setEditUserName] = useState("");
  const [editEmail, setEditEmail] = useState("");
  const [editphone, setEditPhone] = useState("");
  const [editUrl, setEditUrl] = useState("");
  const [addMemberShow, setAddMemberShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState("");
  const [clubName, setClubName] = useState("");
  const [clubSelected, setClubSelected] = useState(false);
  const [clubSelectShow, setClubSelectShow] = useState(false);
  const [selectedClubs, setSelectedClubs] = useState([]);
  const [EditClubSelectShow, setEditClubSelectShow] = useState(false);
  const [userId, setUserId] = useState("");
  const [updatePopup, setUpdatePopup] = useState(false);
  const [urlPopup, setUrlPopup] = useState(false);
  const [editClubs, setEditClubs] = useState([]);
  const [memberDelete, setMemberDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [conditionPopup, SetConditionPopup] = useState(false)
  const [showPasswordPopup, setShowPasswordPopup] = useState(false);
  const [editPassword, setEditPassword] = useState("");
  const [confirmeditPassword, setConfirmEditPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordMessage, setpasswordMessage] = useState("");
  const [urlData, setUrlData] = useState("");
  const [deleteUser, setDeleteUser] = useState("");
  const [userSearchInput, setUserSearchInput] = useState('');
  const [sharePopup, setSharePopup] = useState(false);
  const [sharedUserCredentials, setSharedUserCredentials] = useState({
    username: '',
    password: '',
    email: '',
  });
  const [credCopySnack, setCredCopySnack] = useState(false);
  const [credShareSnack, setCredShareSnack] = useState(false);
  const [getRoleData, setGetRoleData] = useState([])



  const handleRoleChange = (organizationId) => {
    const data = {
      orgId: organizationId,
      clubId: "",
    };
    console.log(data);
    dispatch(RoleGet(data));
  };
  const handleClubRoleChange = (id) => {
    const data = {
      orgId: selectedOrgId,
      clubId: id,
    };
    console.log(data);
    dispatch(RoleGet(data));
  };

  useEffect(() => {
    if (userRole === "OrganizationAdmin") {
      setSelectedOrgId(orgID)
      usersListByOrg(orgID)
      setUserShow(true)
      setAddMemberShow(true)
    }
    else {

      OrgGet()
    }
  }, [userRole])

  const itemsPerPage = 12; // Adjust as needed
  const users = userData;
  const totalUsers = users.length;

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayUsers = users.slice(startIndex, endIndex);

  const validateForm = () => {
    const errors = {};
    if (userName.trim() === "") {
      errors.userName = "User Name is required";
    }

    if (password.trim() === "") {
      errors.password = "Password is required";
    } else {
      const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,15})(?!\s).*$/;
      if (!passwordRegex.test(password.trim())) {
        errors.password =
          "Password does not match criteria";
      }
    }

    if (phone.trim() === "") {
      errors.phone = "phone number is required";
    } else {
      const phoneRegex = /^[0-9]{10}$/;
      if (!phoneRegex.test(phone.trim())) {
        errors.phone =
          "Phone number does not match criteria";
      }
    }
    if (selectedRoleName === "") {
      errors.selectedRole = "Role is required";
    }
    if (email.trim() === "") {
      errors.email = "Email is required";
    } else {
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
      if (!emailRegex.test(email.trim())) {
        errors.email = "Invalid email format";
      }
    }

    const urlRegex = /^$|(https?|ftp):\/\/(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[^\s]*)?$/;
    if (!urlRegex.test(url.trim())) {
      errors.url = "url does not match criteria";
    }
    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const editForm = () => {
    const errors = {};


    if (editEmail.trim() === "") {
      errors.editEmail = "Email is required";
    } else {
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
      if (!emailRegex.test(editEmail.trim())) {
        errors.editEmail = "Invalid email format";
      }
    }

    if (selectedRoleName === "") {
      errors.selectedRoleName = "Role is required";
    }
    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleCheckboxChange = (event) => {
    const clubId = event.target.id;
    if (event.target.checked) {
      // Add the selected club ID to the list
      setSelectedClubs((prevSelectedClubs) => [...prevSelectedClubs, clubId]);
    } else {
      // Remove the unselected club ID from the list
      setSelectedClubs((prevSelectedClubs) =>
        prevSelectedClubs.filter((id) => id !== clubId)
      );
    }
  };

  const handleClub = (clubID) => {
    if (Array.isArray(editClubs)) {
      if (editClubs.includes(clubID)) {
        // Use filter to remove the clubID from the array
        setEditClubs(editClubs.filter((id) => id !== clubID));
      } else {
        // Use spread operator to add the clubID to the array
        setEditClubs([...editClubs, clubID]);
      }
    } else {
      // Initialize selectedClubs as an array with the current club ID
      setEditClubs([editClubs, clubID]);
    }
  };

  const copyToClipboard = () => {
    try {
      const textToCopy = `Username: ${sharedUserCredentials.username}\nPassword: ${sharedUserCredentials.password}\nUrl: ${sharedUserCredentials.url}`;
      // await navigator.clipboard.writeText(textToCopy);
      const textarea = document.createElement('textarea');
      textarea.value = textToCopy;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea)
      setCredCopySnack(true);
    } catch (error) {
      console.error('Failed to copy to clipboard:', error);
    }
  };

  //Organization list API
  async function OrgGet() {
    const reqData = {
      role: userRole,
      user_id: userIdForOrg
    }
    await fetch(`${baseUrl}/organizations/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqData)
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "orgData");
        setOrgList(data.data);

      });
  }

  async function getRole() {
    await fetch(
      `${baseUrl}/role/get`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data, 'list role data');
        if (data.status === true) {
          setGetRoleData(data.data);
        }
      });
  }


  //user list
  async function usersListByOrg(orgId) {
    await fetch(`${baseUrl}/users_under_organization`, {
      method: "POST",
      body: JSON.stringify({
        org_id: orgId,
        role: userRole
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setUserData(data.data);
      });
  }

  //role change
  async function changeRole(roleid, id) {
    await fetch(`${baseUrl}/user/role/change/admin`, {
      method: "POST",
      body: JSON.stringify({
        user_id: id,
        role: roleid,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {

        }
      });
  }

  //mentor list
  async function allMentorList() {
    await fetch(`${baseUrl}/mentor/get/by/org`, {
      method: "POST",
      body: JSON.stringify({
        org_id: selectedOrgId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setMentorList(data.data);
        console.log(mentorList);
      });
  }

  //mentor update
  async function mentorUpdate(mentorID, UserID) {
    await fetch(`${baseUrl}/support/member/learner/mapping/add`, {
      method: "POST",
      body: JSON.stringify({
        SupportMemberID: mentorID,
        LearnerID: UserID,
        LearnerOrganizationID: selectedOrgId,
        LearnerClubID: selectedCLubId,
        MappingCreatedBy: adminId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {

        }
      });
  }

  //mapped mentor list
  async function mappedMentorView(UserID) {
    await fetch(`${baseUrl}/user/mapped/mentors`, {
      method: "POST",
      body: JSON.stringify({
        user_id: UserID,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSingleMentorList(data.data);
      });
  }

  //member add
  async function memberAdd(e) {
    const bodyData = {
      Username: userName,
      Password: password,
      Email: email,
      Url: url,
      org_id: selectedOrgId,
      Role: selectedRoleName,
      Phone: phone
    };
    const isFormValid = validateForm();

    if (isFormValid) {
      await fetch(`${baseUrl}/users/add`, {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.status === "success") {
            setMemberCreate(false);
            usersListByOrg(selectedOrgId)
            setErrors({})
            setApiError('')
            setSelectedRoleName('')
            setPassword('')
            setUserName('')
            setEmail('')
            setPhone('')
            setUrl('')
          } else {
            setApiError(data.message);
          }
        });
    }
  }

  //member edit

  async function memberEdit(e) {
    const bodyData = {
      user_id: userId,
      email: editEmail,
      url: editUrl,
      // phone: editphone,
      role: selectedRoleName,
    };
    await fetch(`${baseUrl}/users/edit`, {
      method: "POST",
      body: JSON.stringify(bodyData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, '-----data');
        if (data.status === true) {
          setUpdatePopup(false)
          usersListByOrg(selectedOrgId)
          setErrors({})
          setApiError('')
          setEditEmail('')
          // setEditPhone('')
          setEditUrl('')
          setSelectedRoleName('')
        }
      });
  }
  //member deleting api

  // url editing
  async function popupUrlEdit(e) {
    const bodyData = {
      user_id: userId,
      url: editUrl,
    };
    await fetch(`${baseUrl}/url/edit`, {
      method: "POST",
      body: JSON.stringify(bodyData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, '-----data');
        if (data.status === true) {
          setEditUrl('')
          usersListByOrg(selectedOrgId)
          setUrlPopup(false);
        }
      });
  }


  async function DeleteMember() {
    await fetch(`${baseUrl}/users/delete`, {
      method: "POST",
      body: JSON.stringify({
        user_id: userId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, 'deletmember');
        if (data.status === true) {
          setMemberDelete(false);
          usersListByOrg(selectedOrgId)
        }
      });
  }
  async function passwordReset() {
    await fetch(`${baseUrl}/password/reset`, {
      method: "POST",
      body: JSON.stringify({
        user_id: userId,
        new_pwd: editPassword
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setShowPasswordPopup(false)
          usersListByOrg(selectedOrgId)
        }
      });
  }

  async function searchUser() {
    if (userSearchInput.trim() === "") {
      usersListByOrg(selectedOrgId);
    } else {
      await fetch(`${baseUrl}/search/users`, {
        method: "POST",
        body: JSON.stringify({
          org_id: selectedOrgId,
          search_value: userSearchInput,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) =>
          res.json().then((data) => {
            console.log(data, "search data");
            if (data.status === true) {
              setUserData(data.data);
            } else {
              setUserData([]);
            }
          })
        );
    }
  }

  async function shareCredential() {
    await fetch(`${baseUrl}/send/user/credential`, {
      method: "POST",
      body: JSON.stringify({
        Username: sharedUserCredentials.username,
        Password: sharedUserCredentials.password,
        Email: sharedUserCredentials.email,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) =>
        res.json().then((data) => {
          console.log(data, 'credential data');
          setCredShareSnack(true);

        }))
  }



  useEffect(() => {
    // OrgGet();
  }, [key]);

  return (
    <div className={style.Container}>
      <div className={style.Header}>
        <div className={style.HeaderText}>
          <p>User Management</p>
        </div>
        <div className={style.HeadButton}>
          {addMemberShow ? (
            <button
              onClick={() => {
                getRole()
                setMemberCreate(true);
              }}
            >
              Add Member
            </button>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className={style.select_section}>
        {userRole === "SuperAdmin" || userRole === "Partner" ?
          <div className={style.org_select}>
            {/* <select
              name=""
              id=""
              onChange={(e) => {
                const OrgID = e.target.value;
                setSelectedOrgId(OrgID);
                setUserShow(true)
                setAddMemberShow(true)
                usersListByOrg(OrgID);

              }}
            >
              <option value="" style={{ textAlign: 'center' }}>Select Organization</option>
              {orgList.length > 0 &&
                orgList.map((org) => {
                  return (
                    <option key={org.org_id} value={org.org_id} style={{ textAlign: 'center' }}>
                      {org.org_name}
                    </option>
                  );
                })}
            </select> */}
            <Autocomplete
              disablePortal
              id=""
              options={orgList.map((org) => ({ value: org.org_id, label: org.org_name }))}
              onChange={(event, selectedOption) => {
                if (selectedOption) {
                  const OrgID = selectedOption.value;
                  setSelectedOrgId(OrgID);
                  setUserShow(true);
                  setAddMemberShow(true);
                  usersListByOrg(OrgID);
                }
              }}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Select Organizations" />}
            />
          </div>
          : ''}
        <div className={style.searchInput}>
          <input
            type="text"
            placeholder="Search users..."
            value={userSearchInput}
            onChange={(e) => {
              const selectedValue = e.target.value;
              setUserSearchInput(selectedValue);
            }}
            onKeyUp={searchUser}
          />
        </div>
      </div>

      {userShow ? (
        <div className={style.userManagement}>
          {/* <div className={style.UserManagementHeader}>
            <div className={style.Search}>
              <input type="search" name="" id="" placeholder="Search here" />
              <FcSearch />
            </div>
            <div className={style.buttonSection}>
              <button
                onClick={() => {
                  setRoleChange(true);
                }}
              >
                Role Change
              </button>
             
            </div>
          </div> */}

          <div className={style.List}>
            {displayUsers.length > 0 &&
              displayUsers.map((data, i) => {
                return (
                  <div className={style.userCards} key={i}>
                    <div className={style.cardTop}>
                      <div className={style.userImage}>
                        <BsPersonCircle />
                      </div>
                      <div className={style.userDetail}>
                        <h6>
                          <span> User Name :</span> {data.Username}
                        </h6>
                        <p>
                          <span> Role :</span>
                          {data.Role === 'OrganizationAdmin' ? 'Organization Admin' :
                            data.Role === 'SuperAdmin' ? 'Super Admin' :
                              data.Role === 'Partner' ? 'Partner' :
                                data.Role}
                        </p>
                        {/* <p>{data.year_of_experience} year Experiance</p> */}
                      </div>
                    </div>
                    <div className={style.cardBotton}>
                      <div className={style.bottom_left}>
                        <div className={style.iconContainer}>
                          <BiEdit
                            onClick={() => {
                              setUpdatePopup(true);
                              setEditEmail(data.Email);
                              setEditPhone(data.phone);
                              setEditUserName(data.Username);
                              setEditUrl(data.url)
                              setUserId(data.user_id);
                              setSelectedRoleName(data.Role)
                            }}
                          />
                          <span className={style.tooltipText}>Edit</span>
                        </div>
                        <div className={style.iconContainer}>
                          <RiDeleteBinLine
                            className={style.deleteButton}
                            onClick={() => {
                              setUserId(data.user_id);
                              setMemberDelete(true);
                              setDeleteUser(data.Username)
                            }}
                          />
                          <span className={style.tooltipText}>Delete</span>
                        </div>
                        <div className={style.iconContainer}>
                          <RiSettingsLine className={style.passwordRest}
                            onClick={() => {
                              setShowPasswordPopup(true)
                              setUserId(data.user_id);
                            }} />
                          <span className={style.tooltipText}>Reset Password</span>
                        </div>
                        <div className={style.iconContainer}>
                          <BsGraphUpArrow
                            className={style.settingsButton}
                            onClick={() => {
                              setUrlPopup(true)
                              setUrlData(data.url)
                              setEditUrl(data.url)
                              setUserId(data.user_id);
                            }}
                          />
                          <span className={style.tooltipText}>Url</span>
                        </div>
                        <div className={style.iconContainer}>
                          <IoMdShareAlt
                            className={style.settingsButton}
                            onClick={() => {
                              setSharedUserCredentials({
                                username: data.Username,
                                password: data.Password,
                                email: data.Email,
                                url: 'http://13.201.131.242/login'
                              });
                              setSharePopup(true);
                            }}
                          />
                          <span className={style.tooltipText}>Share credentials</span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className={style.pageContainer}>
            <Pagination
              pageCount={Math.ceil(totalUsers / itemsPerPage)}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {roleChange ? (
        <>
          <div
            onClick={() => {
              setRoleChange(false);
            }}
            className={style.RoleOverlay}
          ></div>
          <div className={style.roleSelected}>
            <div className={style.roleCLose}>
              <AiOutlineClose
                onClick={() => {
                  setRoleChange(false);
                }}
              />
            </div>
            <div className={style.roleHead}>
              <h4>User List</h4>
            </div>
            <div className={style.userList}>
              <table>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>UserName</th>
                  <th>Role</th>
                  <th>New Role</th>
                  <th>Action</th>
                </tr>

                {userData.length > 0 &&
                  userData.map((data, i) => {
                    return (
                      <tr>
                        <td>{data.FirstName}</td>
                        <td>{data.LastName}</td>
                        <td>{data.Username}</td>
                        <td>{data.role}</td>
                        <td>
                          <select
                            name=""
                            id=""
                            onChange={(e) => {
                              const selectedRoleID = e.target.value;
                              setSelectedRoleId(selectedRoleID);
                            }}
                          >
                            <option value="">Select Role</option>
                            {roleData.length > 0 &&
                              roleData.map((role, i) => {
                                return (
                                  <option
                                    value={role.role_id}
                                    key={role.role_id}
                                  >
                                    {role.role_name}
                                  </option>
                                );
                              })}
                          </select>
                        </td>
                        <td>
                          <button
                            className={style.Change_role}
                            onClick={() => {
                              changeRole(selectedRoleId, data._id);
                            }}
                          >
                            Change Role
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </table>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {mentorChange ? (
        <>
          <div
            onClick={() => {
              setMentorChange(false);
            }}
            className={style.RoleOverlay}
          ></div>
          <div className={style.roleSelected}>
            <div className={style.roleCLose}>
              <AiOutlineClose
                onClick={() => {
                  setMentorChange(false);
                }}
              />
            </div>
            <div className={style.roleHead}>
              <h4>Mentor Mapping</h4>
            </div>
            <div className={style.userList}>
              <table>
                <tr>
                  <th>UserName</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Assigned Mentor</th>
                  <th>New Mentor</th>
                  <th>Action</th>
                </tr>

                {userData.length > 0 &&
                  userData.map((data, i) => {
                    return (
                      <tr>
                        <td>{data.Username}</td>
                        <td>{data.Email}</td>
                        <td>{data.role}</td>
                        <td>
                          <button
                            className={style.viewButton}
                            onClick={() => {
                              setMappedMentorList(true);
                              mappedMentorView(data._id);
                            }}
                          >
                            View
                          </button>
                        </td>
                        <td>
                          <select
                            name=""
                            id=""
                            onChange={(e) => {
                              const selectedMenterID = e.target.value;
                              setSelectedMentorId(selectedMenterID);
                            }}
                          >
                            <option value="">Select Mentor</option>
                            {mentorList.length > 0 &&
                              mentorList.map((mentor, i) => {
                                return (
                                  <option value={mentor._id} key={mentor._id}>
                                    {mentor.Username}({mentor.about_experience})
                                  </option>
                                );
                              })}
                          </select>
                        </td>
                        <td>
                          <button
                            onClick={() => {
                              mentorUpdate(selectedMentorId, data._id);
                            }}
                            className={style.mentorAdd}
                          >
                            Add Mentor
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </table>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {mappedMentorList ? (
        <>
          <div
            onClick={() => {
              setMappedMentorList(false);
            }}
            className={style.RoleOverlay}
          ></div>
          <div className={style.AssignedMentorList}>
            <div className={style.listClose}>
              <AiOutlineClose
                onClick={() => {
                  setMappedMentorList(false);
                }}
              />
            </div>
            <div className={style.listHead}>
              <h4>Assigned Mentor List</h4>
            </div>
            <div className={style.allMentorList}>
              <table>
                <tr>
                  <th>Name</th>
                  <th>Department</th>
                  <th>Change Mentor</th>
                  <th>Action</th>
                </tr>

                {singleMentorList.length > 0 &&
                  singleMentorList.map((list, i) => {
                    return (
                      <tr>
                        <td>{list.Username}</td>
                        <td>{list.about_experience}</td>{" "}
                        <td>
                          <select
                            name=""
                            id=""
                            onChange={(e) => {
                              const selectedMenterID = e.target.value;
                              setSelectedMentorId(selectedMenterID);
                            }}
                          >
                            <option value="">Select Mentor</option>
                            {mentorList.length > 0 &&
                              mentorList.map((mentor, i) => {
                                return (
                                  <option value={mentor._id} key={mentor._id}>
                                    {mentor.Username}({mentor.about_experience})
                                  </option>
                                );
                              })}
                          </select>
                        </td>
                        <td>
                          <button>Update Mentor</button>
                        </td>
                      </tr>
                    );
                  })}
              </table>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {memberCreate ? (
        <>
          <div
            className={style.Overlay}
            onClick={() => {
              setMemberCreate(false);
              setErrors({})
              setApiError('')
              setSelectedRoleName('')
              setPassword('')
              setUserName('')
              setEmail('')
              setPhone('')
              setUrl('')
            }}
          ></div>
          <div className={style.memberCreate}>
            <div className={style.memberClose}>
              <AiOutlineClose
                onClick={() => {
                  setMemberCreate(false);
                  setErrors({})
                  setApiError('')
                  setSelectedRoleName('')
                  setPassword('')
                  setUserName('')
                  setEmail('')
                  setPhone('')
                  setUrl('')
                }}
              />
            </div>
            <div className={style.membersection}>
              {/* <input
                type="text"
                name=""
                id="FirstName"
                placeholder="First Name"
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              /> */}
              {/* <input
                type="text"
                name=""
                id="secondName"
                placeholder="Second Name"
                onChange={(e) => {
                  setSecondName(e.target.value);
                }}
              /> */}
              <label className={style.topLabel}>User name</label>
              <input
                type="text"
                name=""
                id="UserName"
                placeholder=""
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
              />
              <label className={style.topLabel}>Password</label>
              <div className={style.password}>
                <AiOutlineInfoCircle onClick={() => {
                  SetConditionPopup(true)
                }} />
                <input
                  type={showPassword ? "text" : "password"}
                  name=""
                  id="password"
                  placeholder=""
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                <BiLockOpenAlt
                  onClick={() => {
                    togglePasswordVisibility();
                  }}
                />
              </div>
              <label className={style.topLabel}>Phone</label>
              <input
                type="text"
                name=""
                id="phone"
                placeholder=""
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              />
              <label className={style.topLabel}>Email</label>
              <input
                type="mail"
                name=""
                id="mail"
                placeholder=""
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <label className={style.topLabel}>Url</label>
              <input
                type="text"
                name=""
                id="url"
                placeholder=""
                onChange={(e) => {
                  setUrl(e.target.value);
                }}
              />
              <select
                className={style.roleSelection}
                name=""
                id=""
                onChange={(e) => {
                  const Rolename = e.target.value;
                  setSelectedRoleName(Rolename);
                }}
              >
                <option value="">Select Role</option>
                {getRoleData.length > 0 &&
                  getRoleData
                  .filter(role => role.role_name !== 'SuperAdmin')
                  .map((role, i) => {
                    return (
                      <option value={role.role_name} key={role.role_id}>
                        {role.role_name}
                      </option>
                    );
                  })}
              </select>

              {/* <div className={style.createButton}>
                <button
                  className={style.create}
                >
                  Share
                </button>
                <button
                  className={style.create}
                >
                  Copy
                </button>

              </div> */}

              <div className={style.createButton}>
                <button
                  className={style.create}
                  onClick={() => {
                    memberAdd();
                  }}
                >
                  Create
                </button>
                <button
                  className={style.cancel}
                  onClick={() => {
                    setMemberCreate(false);
                    setErrors({})
                    setApiError('')
                    setSelectedRoleName('')
                    setPassword('')
                    setUserName('')
                    setEmail('')
                    setPhone('')
                    setUrl('')
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className={style.errorMessages}>
              {Object.values(errors).map((error, index) => (
                <div key={index} className={style.errorValue}>
                  <p>{error}</p>
                </div>
              ))}
              <div className={style.errorValue}>
                <p>{apiError}</p>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {urlPopup ? (
        <>
          <div
            className={style.Overlay}
            onClick={() => {
              setUrlPopup(false);
              setEditUrl('')
            }}
          ></div>
          <div className={style.urlPopup}>
            <label className={style.topLabel}>Dashboard Url</label>
            <span className={style.urlPopupClose}>
              <AiOutlineClose
                onClick={() => {
                  setUrlPopup(false);
                  setEditUrl('')
                }}
              />
            </span>
            <div className={style.urlHead}>
              <input
                value={editUrl}
                type="text"
                name=""
                id=""
                placeholder=""
                onChange={(e) => {
                  setEditUrl(e.target.value);
                }}
              />
              <button
                onClick={() => {
                  popupUrlEdit();
                }}
              >
                Update
              </button>
            </div>
            <iframe
              src={urlData}
              frameborder="0"
              style={{ width: '100%', height: '100%' }}
            ></iframe>
            <div className={style.errorMessages}>
              {Object.values(errors).map((error, index) => (
                <div key={index} className={style.errorValue}>
                  <p>{error}</p>
                </div>
              ))}
              <div className={style.errorValue}>
                <p>{apiError}</p>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {updatePopup ? (
        <>
          <div
            className={style.Overlay}
            onClick={() => {
              setUpdatePopup(false);
              setErrors({})
              setApiError('')
              setSelectedRoleName('')
              setEditEmail('')
              // setEditPhone('')
              setEditUrl('')
            }}
          ></div>
          <div className={style.memberCreate}>
            <div className={style.memberClose}>
              <AiOutlineClose
                onClick={() => {
                  setUpdatePopup(false);
                  setErrors({})
                  setApiError('')
                  setSelectedRoleName('')
                  setEditEmail('')
                  // setEditPhone('')
                  setEditUrl('')
                }}
              />
            </div>
            <div className={style.membersection}>
              <label className={style.topLabel}>Username</label>
              <input
                value={editUserName}
                type="text"
                name=""
                id=""
                readOnly
                placeholder=""
                onChange={(e) => {
                  setEditUserName(e.target.value);
                }}
              />
              <label className={style.topLabel}>Phone</label>
              <input
                value={editphone}
                type="text"
                name=""
                id=""
                readOnly
                placeholder=""
                onChange={(e) => {
                  setEditPhone(e.target.value);
                }}
              />

              <label className={style.topLabel}>Email</label>
              <input
                value={editEmail}
                type="mail"
                name=""
                id=""
                placeholder=""
                onChange={(e) => {
                  setEditEmail(e.target.value);
                }}
              />

              <label className={style.topLabel}>Url</label>
              <input
                value={editUrl}
                type="text"
                name=""
                id=""
                placeholder=""
                onChange={(e) => {
                  setEditUrl(e.target.value);
                }}
              />

              <select
                className={style.roleSelection}
                name=""
                id=""
                onChange={(e) => {
                  const Rolename = e.target.value;
                  setSelectedRoleName(Rolename);
                }}
                value={selectedRoleName}
              >
                <option value="">Select Role</option>
                <option value="OrganizationAdmin">Organization Admin</option>
                <option value="User">User</option>
              </select>

              <div className={style.createButton}>
                <button
                  className={style.create}
                  onClick={() => {
                    memberEdit();
                  }}
                >
                  Update
                </button>
                <button
                  className={style.cancel}
                  onClick={() => {
                    setUpdatePopup(false);
                    setErrors({})
                    setApiError('')
                    setSelectedRoleName('')
                    setEditEmail('')
                    // setEditPhone('')
                    setEditUrl('')
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className={style.errorMessages}>
              {Object.values(errors).map((error, index) => (
                <div key={index} className={style.errorValue}>
                  <p>{error}</p>
                </div>
              ))}
              <div className={style.errorValue}>
                <p>{apiError}</p>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {conditionPopup ?
        <div className={style.PasswordPopup}>
          <div className={style.content}>
            <ul>
              <p>Password must include:</p>
              <li>6-15 Characters</li>
              <li>At least 1 capital letter</li>
              <li>At least 1 number</li>
              <li>At least 1 special character</li>
              <li>No spaces</li>
            </ul>
          </div>
          <div className={style.action}>
            <button
              onClick={() => {
                SetConditionPopup(false)
              }}
            >
              OK
            </button>
          </div>
        </div>
        : ''}

      {memberDelete ? (
        <>
          <div
            onClick={() => {
              setMemberDelete(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.deletePopup}>
            <div className={style.deleteClose}>
              <AiOutlineClose
                onClick={() => {
                  setMemberDelete(false);
                }}
              />
            </div>
            <div className={style.deleteSection}>
              <div className={style.popupText}>
                <p>Are you sure you want to delete <br /><span>{deleteUser}</span> ?</p>
              </div>
              <div className={style.popupDelete}>
                <button
                  className={style.orgDelete}
                  onClick={() => {
                    DeleteMember();
                  }}
                >
                  Delete
                </button>
                <button
                  className={style.orgDeleteCancel}
                  onClick={() => {
                    setMemberDelete(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {showPasswordPopup ? (
        <>
          <div className={style.Overlay} onClick={() => {
            setShowPasswordPopup(false)
          }}></div>
          <div className={style.memberCreate}>
            <div className={style.memberClose}>
              <AiOutlineClose onClick={() => {
                setShowPasswordPopup(false)
              }} />
            </div>
            <div className={style.membersection}>
              <label className={style.topLabel}>Password</label>
              {passwordMessage && (
                <div style={{ color: "red", fontSize: "1.2rem" }}>{passwordMessage}</div>
              )}
              <div className={style.password}>
                <AiOutlineInfoCircle onClick={() => {
                  SetConditionPopup(true)
                }} />
                <input
                  type={passwordVisible ? "text" : "password"}
                  name=""
                  id="newpassword"
                  placeholder=""
                  value={editPassword}
                  onChange={(e) => {
                    setEditPassword(e.target.value)
                    setpasswordMessage("")
                  }}
                />
                <BiLockOpenAlt
                  onClick={() => {
                    setPasswordVisible(!passwordVisible)
                  }}
                />
              </div>
              <label className={style.topLabel}>Retype Password</label>
              <div className={style.password}>
                <input
                  type="password"
                  name=""
                  id="confirmpassword"
                  onClick={() => {
                    if (editPassword !== "" && (/[A-Z]/.test(editPassword) === false || /\d/.test(editPassword) === false || editPassword.length < 6 || editPassword.length > 15)) {
                      setpasswordMessage("Password does not match Criteria")
                    }
                  }}
                  placeholder=""
                  value={confirmeditPassword}
                  onChange={(e) => {
                    setConfirmEditPassword(e.target.value)
                  }}
                />

              </div>

              <div className={style.createButton}>
                <button
                  className={style.create}
                  onClick={() => {
                    if (
                      editPassword !== "" &&
                      (
                        /[A-Z]/.test(editPassword) === false ||
                        /\d/.test(editPassword) === false ||
                        editPassword.length < 6 ||
                        editPassword.length > 15
                      )
                    ) {
                      setpasswordMessage("Password does not match Criteria");
                    } else if (editPassword !== confirmeditPassword) {
                      setpasswordMessage("Passwords do not match");
                    } else {
                      passwordReset();
                    }
                  }}
                >
                  Update
                </button>
                <button
                  className={style.cancel}
                  onClick={() => {
                    setShowPasswordPopup(false)
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      ) : ("")}

      {sharePopup ? (
        <>
          <div
            className={style.Overlay}
            onClick={() => {
              setSharePopup(false);
              setErrors({})
              setApiError('')
            }}
          ></div>
          <div className={style.memberCreate}>
            <div className={style.memberClose}>
              <AiOutlineClose
                onClick={() => {
                  setSharePopup(false);
                  setErrors({})
                  setApiError('')
                }}
              />
            </div>
            <div className={style.membersection}>
              <label className={style.topLabel}>Username</label>
              <input
                value={sharedUserCredentials.username}
                type="text"
                name=""
                id=""
                readOnly
                placeholder=""
              />

              <label className={style.topLabel}>Password</label>
              <input
                value={sharedUserCredentials.password}
                type="text"
                name=""
                id=""
                readOnly
                placeholder=""
              />

              <label className={style.topLabel}>Url</label>
              <input
                value={sharedUserCredentials.url}
                type="text"
                name=""
                id=""
                readOnly
                placeholder=""
              />

            </div>
            <div className={style.createButton}>
              <button
                className={style.create}
                onClick={() => {
                  shareCredential();
                  // setSharePopup(false);
                  // setErrors({});
                  // setApiError('');
                }}
              >
                Share
              </button>
              <Snackbar
                open={credShareSnack}
                autoHideDuration={2000}
                onClose={() => setCredShareSnack(false)}
                message="Email sent successfully"
              />
              <button
                className={style.create}
                onClick={copyToClipboard}
              >
                Copy
              </button>
              <Snackbar
                open={credCopySnack}
                autoHideDuration={2000}
                onClose={() => setCredCopySnack(false)}
                message="Content copied to clipboard!"
              />

            </div>
            <div className={style.errorMessages}>
              {Object.values(errors).map((error, index) => (
                <div key={index} className={style.errorValue}>
                  <p>{error}</p>
                </div>
              ))}
              <div className={style.errorValue}>
                <p>{apiError}</p>
              </div>
            </div>
          </div>
        </>
      ) : ("")}

    </div>
  );
};

export default User;
