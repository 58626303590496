import React, { useState, useEffect } from "react";
import style from "./Sidebar.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { CgOrganisation } from "react-icons/cg";
import { PiUsersThreeFill } from "react-icons/pi";
import { BiLogOutCircle } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { UserProfile } from "../../action/User";
import { TbSettingsExclamation } from "react-icons/tb";
import { IoSettingsOutline } from "react-icons/io5";
import { IoCloudUploadOutline } from "react-icons/io5";
import { MdOutlineDriveFolderUpload } from "react-icons/md";
import icon from "../../assets/1-page.png";
import { MdCurrencyRupee } from "react-icons/md";
import { MdOutlineFeaturedPlayList } from "react-icons/md"
import { IoPricetag } from "react-icons/io5";
import { RiBillLine } from "react-icons/ri";
import { red } from "@mui/material/colors";


const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const admin = localStorage.getItem("User");
  const orgname = localStorage.getItem("Orgname")
  const orgimage = localStorage.getItem("Orgimage")

  const { profile } = useSelector((state) => state.profileData);
  console.log(profile);

  const [logout, setLogout] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");

  const handleLogout = (e) => {
    localStorage.removeItem("access-token");
    localStorage.removeItem("adminStatus");
    localStorage.removeItem("selectedCategory");
    localStorage.removeItem("OrgId")
    localStorage.removeItem("countrydetails")
    localStorage.removeItem("User")
    localStorage.removeItem("Orgname")
    localStorage.removeItem("Orgimage")
    navigate("/login");
    window.location.reload();
  };

  const handleCategorySelection = (category) => {
    navigate(`/${category}`);
    setSelectedCategory(category);
  };

  useEffect(() => {
    const path = location.pathname;
    if (path === "/organization") setSelectedCategory("organization");
    else if (path === "/user") setSelectedCategory("user");
    else if (path === "/pricing") setSelectedCategory("pricing");
    else if (path === "/features") setSelectedCategory("features");
    else if (path === "/pricing/actions") setSelectedCategory("pricing");
    else if (path === "/packages") setSelectedCategory("packages");
    else if (path === "/billing") setSelectedCategory("billing");
    else if (path === "/configuration") setSelectedCategory("configuration");

  }, [location]);

  // useEffect(() => {
  //   const storedCategory = localStorage.getItem("selectedCategory");
  //   if (storedCategory) {
  //     setSelectedCategory(storedCategory);
  //   }
  // }, []);

  useEffect(() => {
    dispatch(UserProfile());
  }, []);

  return (
    <div className={style.Sidebar}>
      <div className={style.Header}>
        <div className={style.headerImage}>
          <img src="https://www.tasshamjit.com/wp-content/uploads/2020/09/cropped-logo_main-1.png" alt="" />
          {/* <img src={orgimage} alt="" /> */}
        </div>
      </div>
      <div className={style.adminName}>
        <p>{orgname}</p>
      </div>
      <div className={style.Menu}>
        {admin === "SuperAdmin" || admin === "Partner" ?
          <>
            <div
              className={
                selectedCategory === "organization"
                  ? style.selected
                  : style.menuIcons
              }
              onClick={() => handleCategorySelection("organization")}
            >
              <CgOrganisation />
              <p>Organizations</p>
            </div>
            {/* <div
            className={
              selectedCategory === "pricing" ? style.selected : style.menuIcons
            }
            onClick={() => {
              handleCategorySelection("pricing");
            }}
          >
            <MdCurrencyRupee />
            <p>Pricing</p>
          </div> */}
            {/* <div
            className={
              selectedCategory === "features" ? style.selected : style.menuIcons
            }
            onClick={() => {
              handleCategorySelection("features");
            }}
          >
            <MdOutlineFeaturedPlayList />
            <p>Features</p>
          </div> */}
          </>
          : ''}
        <div
          className={
            selectedCategory === "user" ? style.selected : style.menuIcons
          }
          onClick={() => {
            handleCategorySelection("user");
          }}
        >
          <PiUsersThreeFill />
          <p>User Management</p>
        </div>
        {admin == "OrganizationAdmin" ?
          <>
            {/* <div
          className={
            selectedCategory === "packages" ? style.selected : style.menuIcons
          }
          onClick={() => {
            handleCategorySelection("packages");
          }}
        >
         <IoPricetag />
          <p>Packages</p>
        </div> */}
            {/* <div
        className={
          selectedCategory === "billing" ? style.selected : style.menuIcons
        }
        onClick={() => {
          handleCategorySelection("billing");
        }}
      >
       <RiBillLine />
        <p>Billing</p>
      </div> */}
            {/* <div
        className={
          selectedCategory === "configuration" ? style.selected : style.menuIcons
        }
        onClick={() => {
          handleCategorySelection("configuration");
        }}
      >
       <RiBillLine />
        <p>Configuration</p>
      </div> */}
          </>
          : ''
        }
        <div
          className={style.menuIcons}
          onClick={() => {
            setLogout(true);
          }}
        >
          <BiLogOutCircle />
          <p>Logout</p>
        </div>
        {admin === "SuperAdmin" && (
          <div
            className={style.menuIcons}
            onClick={() => {
              handleCategorySelection("reset/password");
            }}
          >
            <IoSettingsOutline />
            <p>Reset Password</p>
          </div>
        )}
      </div>
      <div className={style.Footer}>
        <div className={style.Footertext}>
          <p>Powered By</p>
        </div>
        <div className={style.FooterImage}>
          <img src="https://www.tasshamjit.com/wp-content/uploads/2020/09/cropped-logo_main-1.png" alt="" />
          {/* <img src={icon} alt="" /> */}
        </div>
      </div>
      {logout ? (
        <>
          <div
            className={style.logoutOverlay}
            onClick={() => {
              setLogout(false);
            }}
          ></div>
          <div className={style.Popup}>
            <div className={style.popupClose}>
              <AiOutlineClose
                onClick={() => {
                  setLogout(false);
                }}
              />
            </div>
            <div className={style.popupSection}>
              <div className={style.popupText}>
                <p>Are you sure you want to logout?</p>
              </div>
              <div className={style.PopupButton}>
                <button className={style.logoutBtn}
                  onClick={(e) => {
                    handleLogout();
                  }}
                >
                  YES
                </button>
                <button className={style.logoutBtnCancel}
                  onClick={() => {
                    setLogout(false);
                  }}
                >
                  NO
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}{" "}
    </div>
  );
};

export default Sidebar;
