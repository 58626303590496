import React, { useState } from 'react';
import style from './ResetPassword.module.css'
import { baseUrl } from "../Url";
import { Alert, Snackbar } from '@mui/material';
import { BiLockOpenAlt } from 'react-icons/bi';

function ResetPassword() {
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [newPasswordVisible, setNewPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    const userId = localStorage.getItem("userId");

    async function passwordReset() {
        if (newPassword !== "" && (/[A-Z]/.test(newPassword) === false || /\d/.test(newPassword) === false || newPassword.length < 6 || newPassword.length > 15)) {
            setSnackbarMessage("Password does not match criteria");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }
        if (newPassword !== confirmPassword) {
            setSnackbarMessage("Please check the new password");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }
        await fetch(`${baseUrl}/password/reset`, {
            method: "POST",
            body: JSON.stringify({
                user_id: userId,
                new_pwd: newPassword
            }),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                if (data.status === true) {
                    setSnackbarMessage("Password reset successfully");
                    setSnackbarSeverity("success");
                    setSnackbarOpen(true);
                    setNewPassword("");
                    setConfirmPassword("");
                } else {
                    setSnackbarMessage("Password reset failed");
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            });
    }

    return (
        <div className={style.Container}>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={snackbarOpen}
                onClose={() => {
                    setSnackbarOpen(false)
                }}
                autoHideDuration={3000}
            >
                <Alert
                    variant="filled"
                    severity={snackbarSeverity}
                    sx={{ width: "100%", fontSize: "1.15rem" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <div className={style.Header}>
                <div className={style.HeaderText}>
                    <p>Password Reset</p>
                </div>
            </div>
            <div className={style.bottomContainer}>
                <div className={style.passwordContainer}>
                    <div className={style.input}>
                        <input
                            type={newPasswordVisible ? "text" : "password"}
                            placeholder="New password"
                            value={newPassword}
                            onChange={(e) => {
                                setNewPassword(e.target.value);
                            }}
                        />
                        <BiLockOpenAlt
                                onClick={() => {
                                    setNewPasswordVisible(!newPasswordVisible);
                                }}
                                className={style.newPasswrdEyeIcon}
                            />
                        <input
                            type={confirmPasswordVisible ? "text" : "password"}
                            placeholder="Confirm new password"
                            value={confirmPassword}
                            onChange={(e) => {
                                setConfirmPassword(e.target.value);
                            }}
                        />
                        <BiLockOpenAlt
                            onClick={() => {
                                setConfirmPasswordVisible(!confirmPasswordVisible);
                            }}
                            className={style.confirmPasswrdEyeIcon}
                        />
                        <button
                            onClick={() => {
                                passwordReset()
                            }}
                        >
                            Reset Password
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword