import { createStore, applyMiddleware, combineReducers } from "redux";
import { thunk } from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";
import {  RoleReducer, profileReducer, userReducer } from './reducer/User'
import { ClubReducer } from './reducer/List'

const reducers = combineReducers({
    userReducer: userReducer,
    profileData: profileReducer,
    roleData: RoleReducer,
    clubData: ClubReducer,

})
const store = createStore(
    reducers,
    composeWithDevTools(applyMiddleware(thunk))
  );


export default store;